import state from "./state";

export default{
    init(state,payload){
        state.portfolioArr = payload.portfolio;
    },
    handIsLoad(state,bool){
        state.isLoad = bool
    },
    handIsMenu(state,bool){
        state.isMobileMenu = bool;
    },
    handIsAboutFalse(state,bool){
        state.isAbout = bool
    },
    handIsAboutTrue(state,bool){
        state.isAbout = bool
    }
}