import state from "./state";

export default{
    isLoad(state){
        return state.isLoad;
    },
    portfolioArr(state){
        return state.portfolioArr;
    },
    isAbout(state){
        return state.isAbout;
    },
    isMobileMenu(state){
        return state.isMobileMenu;
    }
};