<script>
import Portfolio from '@/components/Portfolio/Portfolio.vue';
export default {
  components:{
      Portfolio,
  }
}
</script>

<template>
<Portfolio />
</template>
